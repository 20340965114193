<template>
  <b-modal
    id="modal-success-upload"
    :title="$t('Importar archivo')"
    hide-footer
    modal-class="dialog-600 modal-background--multi-color"
    ok-only
    no-close-on-esc
    no-close-on-backdrop
    centered
  >
    <div class="d-flex flex-column align-items-center text-center py-2">
      <feather-icon :icon="icon || 'CheckCircleIcon'" class="text-success" size="6x"/>
      <div class="py-2">
        <h4>{{ tittle || info.TITTLE }}</h4>
        <p><small>{{ description || info.DESCRIPTION }}</small></p>
      </div>      
      <b-button @click="$bvModal.hide('modal-success-upload')" :variant="`outline-${ variant || 'warning'}`" class="btn px-5">{{$t('Aceptar')}}</b-button>
    </div>
  </b-modal>
</template>

<script>

export default {
  name: 'modal-success-upload',
  props: ['icon', 'description', 'tittle', 'variant'],
  data() {
    return {
      info: {
        TITTLE: '¡La importación se ha completado con éxito!',
        DESCRIPTION: 'Te invitamos a verificar tu bandeja de entrada, ya que recibirás un correo electrónico notificándote sobre el proceso realizado.'
      }
    }
  }
}
</script>
