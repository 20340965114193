<template>
  <b-modal
    id="modal-upload-conditions"
    :title="$t('Importar archivo')"
    modal-class="dialog-800 modal-background--multi-color"
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    centered
    @close="close"
    accept=".xlxs"
    no-stacking
  >
    <div>
      <div>
        <small><b>&#x2022;</b> Esta acción puede tomar varios minutos.</small>
        <form-render
          :form.sync="form"
          :fields="fields"
          :key="keyFormCondRender"
          class="mt-1"
          containerButtonsClass="col-sm-12" ref="formUploadCarrierConditions">
          <template #link>
            <p>
              <b-button @click="getExcel" variant="link" class="p-0 font-weight-normal underline-text text-primary">
                <feather-icon icon="InfoIcon"/> 
                Descargar Formato
              </b-button>
              <span class="small-text"> Descarga la plantilla de ejemplo y sube el archivo con las condiciones del couriers.</span>
            </p>
          </template>
        </form-render>
      </div>
      
      <div class="col-12 py-2 d-flex justify-content-end">    
        <b-button class="float-right" :class="loading.uploadData && 'px-3'" @click="uploadCarrierConditions()" variant="warning" :disabled="loading.uploadData || !form.fileCarrierConditions || !form.email">
          <b-spinner v-if="loading.uploadData" small></b-spinner>
          <span v-else>{{$t('Aceptar')}}</span>
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>

import BaseServices from '@/store/services/index'


export default {
  name: 'modal-upload-conditions',

  data() {
    return {
      keyFormCondRender: 0,
      fields: [],
      form: {
        fileConditions: null
      },
      loading: {
        uploadData: false
      },
      excel: {
        NAME: `Plantilla_${new Date().toLocaleDateString().split('/').join('.')}.xlsx`,
        URL: 'https://storage.cloud.google.com/carrier-services-specific-conditions-prod/plantilla-base/base.xlsx'
      },
      baseService: new BaseServices(this)
    }
  },
  mounted() {
    this.setInitialData()
  },
  methods: {
    setInitialData() {
      this.fields = [
        {fieldType: 'FieldInput', name: 'email', type: 'email', label: 'Correo de notificaciones', containerClass: 'col-sm-12 container-info', clearable: false, validation: 'required|email', aditionalLabel: {type: 'primary', text: '(Una vez ingresado el correo, recibirás una notificación cuando se haya completado la importación).'}},
        { fieldType: 'FieldFileDropArea', name:'fileCarrierConditions', labelFormFile: 'Subir Tarifa', containerClass: 'col-12 container-info mt-2', accept: '.xls, .xlsx', classField:'hide-button-browse', clearable: true },
        { name: 'link', useSlot: true, containerClass: 'col-12' }
      ]
    },
    getExcel() {
      this.downloadLink(this.excel.URL, this.excel.NAME)
    },
    async uploadCarrierConditions() {
      const checkForm = await this.$refs.formUploadCarrierConditions.onlyCheckForm()
      if (!checkForm) return
      this.loading.uploadData = true
      const formData = {
        file: this.form.fileCarrierConditions
      }
      const headers = {
        'email' : this.form.email
      }
      this.baseService.callUploadFileCustomParams('uploadCarrierConditions', formData, {}, null, headers)
        .then(() => this.$bvModal.show('modal-success-upload'))
        .catch((err) => {
          console.error('error', err)
          this.$alert(this.$t('msg-problema-cargar-datos', {code: err.data}))
        })
        .finally(() => {
          this.loading.uploadData = false
        }) 
    },
    close() {
      this.form = {}      
    }
  }
}
</script>

<style lang="scss">
  .underline-text{
    text-decoration-line: underline;
  }
  #fileCarrierConditions > span > div div.remove-botton {
    right: 1rem;
    > button svg {
      width: 1.2rem;
      height: 1.2rem;
    }
  }
</style>