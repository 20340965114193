<template>
  <b-modal
    id="modal-update-transit-days"
    :title="$t('Agregar días adicionales temporales')"
    hide-footer
    modal-class="dialog-900 modal-background--multi-color"
    no-close-on-esc
    no-close-on-backdrop
    @close="onClose"
  >
    <b-tabs>
      <b-tab :title="$t('Individual')">
        <form-render
          :form.sync="form"
          :fields="fields"
          :key="keyFormRenderTransitDays"
          containerButtonsClass="col-sm-12" ref="formRenderTransitDays"
        >
          <template #aditiona_days_temp>
            <div class="d-flex w-100" >
              <b-form-input
                ref="aditional_days"
                placeholder="Ingrese horario de corte"
                type="number"
                :min="0"
                :max="100"
                name="aditional_days"
                v-model="form.aditional_days"
                aria-describedby="input-live-help"
                class="inputApplyDiscountOrIncrease"
                required
                :key="keyIncreaseOrDiscount"
                :class="!hasData.time ? 'is-invalid' : ''"
                @change="val => courtHourChange(val, 'time')"
              ></b-form-input>
              <v-select
                id="increaseOrDiscount"
                v-model="form.increaseOrDiscount"
                :options="courtHoursOptions"
                :clearable="false"
                class="inputIncreaseOrDiscount"
                placeholder="Seleccionar"
                label="text"
                :class="!hasData.option ? 'is-invalid' : ''"
                @input="val => courtHourChange(val, 'option')"
              >
                <template #option="{ name }">
                  <span>{{name}}</span>
                </template>
                <template #selected-option="{ name }">
                  <div style="display: flex; align-items: baseline">
                    {{ name }}
                  </div>
                </template>
              </v-select>
            </div>
          </template>
        </form-render>
        <b-alert class="mb-0 mt-1 p-1" show variant="warning"><b><feather-icon icon="AlertTriangleIcon"/></b><small class="text-dark"> Es importante mantener en consideración que esta acción sobrescribirá los días temporales exitentes.</small></b-alert>

        <b-button @click="ok" :variant="loading ? 'warning' : 'outline-warning'" class="float-md-right mt-2" :disabled="loading">
          <b-spinner v-show="loading" small></b-spinner> Agregar
        </b-button>
      </b-tab>
      <b-tab :title="$t('Masivo')" :disabled="true">
      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import BaseServices from '@/store/services/index'


export default {
  name: 'modal-update-transit-days',
  components: { vSelect },
  props: ['clearBtn'],
  data() {
    return {      
      form: {},
      fields: [],
      keyFormRenderTransitDays: 0,
      keyIncreaseOrDiscount: 2,
      hasData: {
        time: true,
        option: true
      },
      courtHoursOptions: [
        {id: 1, name: 'Restar', text: 'Restar', symb: '-'},
        {id: 2, name: 'Sumar', text: 'Restar', symb: ''}
      ],
      firstCheck: false,
      loading: false,
      baseService: new BaseServices(this)
    }
  },
  computed: {
    ...mapGetters({
      countries: 'getCountries',
      allCarriers: 'getAllCarriers'
    })
  },
  watch: {
    countries () {
      this.setCountries()
    },
    allCarriers() {
      const allCouriersByCountry = this.allCarriers.data.map((carrier) => ({...carrier, text: `[${carrier.code}] ${carrier.name}` }))
      this.setSelectOptions('courier', { options: allCouriersByCountry})
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData() {
      this.fields = [
        { fieldType: 'FieldSelect', label: this.$t('País'), name: 'country', addFlags:true, useLabel: true, clearable:false, containerClass: 'col-12 container-info', validation: 'required', change: this.getAllCouriers },        
        { fieldType: 'FieldSelect', label: this.$t('Courier'), useLabel: true, name: 'courier', containerClass: 'col-6 container-info', validation: 'required', dependency: 'country', change: this.changeCarrier, searchOnType: { fx:  e => this.searchCarrier(e), nChars: 3, debounce: 600, persistSearch: true } },
        { fieldType: 'FieldSelect', clearable: true, label: this.$t('Servicio'), dependency: 'courier', name: 'services', containerClass: 'col-6 container-info', multiple: true },
        { fieldType: 'FieldDatepicker', name: 'start_date', label: this.$t('Fecha de inicio'), containerClass: 'col-6 container-info', showCalendarIcon: true, validation: 'requiredDate', clearable: true, propsVCalendar: { 'min-date': new Date() }, change: this.changeStartDate, autocompleteOff: true },
        { fieldType: 'FieldDatepicker', validation: 'requiredDate', name: 'end_date', label: this.$t('Fecha de fin'), containerClass: 'col-6 container-info', dependency: 'start_date', showCalendarIcon: true, clearable: true, autocompleteOff: true },
        { name: 'aditiona_days_temp', label: this.$t('Días adicionales temporales'), containerClass: 'col-12 container-info', useSlot: true, aditionalLabel: {type: 'danger', text: '*'} }
      ]
      if (!!this.countries.length) this.setCountries()
      this.form.increaseOrDiscount = this.courtHoursOptions[1]
    },
    setCountries () {
      this.setSelectOptions('country', { options: this.countries})
    },
    setSelectOptions (name, { options }) {
      const index = this.fields.findIndex(el => el.name === name)
      if (index !== -1) {
        this.fields[index].options = options
        this.keyFormRenderTransitDays++
      }
    },
    getAllCouriers(_, value) {
      this.setSelectOptions('courier', { options: []})      
      this.form.courier = null
      this.cleanFormServices()
      if (!value) return
      const queryParams = {
        country_id: value.id,
        limit: 10
      }
      this.$store.dispatch('fetchService', {
        name: 'getAllCarriers',
        queryParams,
        hideAlert: true
      })
    },
    cleanFormServices() {
      this.setSelectOptions('services', { options: []})
      this.form.services = null
    },
    changeCarrier(_, value) {
      this.cleanFormServices()
      if (!value) return
      const services = value.services.map(serv => ({...serv, text: serv.name}))
      this.setSelectOptions('services', { options: services})
    },
    searchCarrier (searchOnType) {
      const queryParams = {
        country_id: this.form.country.id,
        limit: 10
      }
      if (searchOnType) queryParams.search_string = searchOnType
      return this.baseService.callService('getAllCarriers', queryParams)
        .then(resp => {
          const response = resp.data
          return response.map((carrier) => ({
            ...carrier,
            text: `[${carrier.code}] ${carrier.name}`
          }))
        })
        .catch(err => {
          console.error(err)
        })
    },
    changeStartDate(_, value) {
      if (!value) {
        this.form.end_date = null
        return
      }
      const startDate = new Date(this.form.start_date)
      const endDate = new Date(this.form.end_date)
      if (startDate.getTime() > endDate.getTime()) this.form.end_date = null
  
      const index = this.fields.findIndex(el => el.name === 'end_date')
      this.fields[index].propsVCalendar = { 'min-date': value }
    },
    courtHourChange(data, from) {
      if (!this.firstCheck) return
      this.hasData[from] = !!data
    },
    async ok (e) {
      e.preventDefault()
      if (!this.firstCheck) this.firstCheck = true
      const form = await this.$refs.formRenderTransitDays.onlyCheckForm()
      this.hasData = {
        option: !!this.form.increaseOrDiscount?.id,
        time: !!this.form.aditional_days
      }
      const isInvalid = form && this.hasData.time && this.hasData.option
      if (!isInvalid) return

      this.loading = true
      const queryParams = {
        country_code: this.form.country.code,
        carrier_code: this.form.courier.code,
        start_date: `${this.$options.filters.moment(this.form.start_date, 'YYYY-MM-DD')} 00:00:00`,
        end_date: `${this.$options.filters.moment(this.form.end_date, 'YYYY-MM-DD')} 23:59:59`,
        service_code: this.form.services ? this.form.services.map(ser => ser.code) : this.form.courier.services.map(ser => ser.code),
        transit_days: Number(`${this.form.increaseOrDiscount.symb}${this.form.aditional_days}`)
      }
      this.$store.dispatch('fetchService', {
        name: 'updateTransitDays',
        queryParams,
        onSuccess: (res) => {
          this.$newSuccess('!Los días adicionales temporales se han agregado con éxito!', 'Ahora puedes verlos reflejados en la plataforma.', 'large')
          this.onClose()
          this.$bvModal.hide('modal-update-transit-days')
        },
        hideAlert: true,
        onError: () => {
          this.loading = false
          this.$newError('!Hubo un erro al intenter agregar lo días adicionales temporales!', 'Verifica el archivo. Si el inconveniente persiste, contacta a nuestro equipo de Support para obtener más información.', 'large')
        }
      })
    },
    onClose() {
      this.loading = false
      this.form = {}
      this.form.increaseOrDiscount = this.courtHoursOptions[1]
      this.$emit('clearBtn')
    }
  }
}
</script>

<style lang="scss">
.option-dimension{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.1rem;
}
.bg-white{
  background-color: white;
}
.inputIncreaseOrDiscount {
  width: 40%;
  > div {
    border-start-start-radius: 0 !important;
    border-end-start-radius: 0 !important;
    border-left: none !important;
    min-height: 100% !important;
  }
  > ul {
    top: auto;
    min-width: 100px;
  }
}
.inputApplyDiscountOrIncrease {
  width: 60%;
  border-start-end-radius: 0 !important;
  border-end-end-radius: 0 !important;
}
</style>
